<script setup lang="ts">
import { computed } from 'vue'
import { type Payee } from '/~/types/api'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

const { translate } = useLocalization()

const props = defineProps<{
  subStatus: Payee['subStatus']
}>()

const statusClass = computed(() => {
  return 'flex items-center gap-2 bg-red-50 border border-red-700 rounded-sm px-2 text-sm text-red-700'
})

const statusIcon = computed(() => {
  return 'heroicons/mini/exclamation-circle'
})

const statusMessage = computed(() => {
  switch (props.subStatus) {
    case 'bsb_invalid':
      return `Invalid ${translate('bankAccount.bsb')}`
    case 'account_invalid':
      return 'Invalid Account'
    case 'remitter_name_invalid':
      return 'Invalid Remitter Name'
    case 'payee_blacklisted':
    default:
      return 'This type of payee cannot be paid on this platform'
  }
})
</script>

<template>
  <div :class="statusClass">
    <base-icon class="flex-shrink-0" :svg="statusIcon" :size="16" />
    <span>{{ statusMessage }}</span>
  </div>
</template>
